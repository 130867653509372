<template>
  <b-sidebar
    id="store-tracking-items"
    ref="modal"
    header-class="headerModalToggle"
    right
    shadow
    size="l"
    width="50%"
    :title="$t('Filiale')"
    no-close-on-esc
    @close="onCancelToggle"
  >
    <template #title>
      <strong style="color: #101828;">{{ $t('Filiale') }}:{{ data.filiale.storeName }}</strong>
    </template>
    <template
      v-slot:header-close
    >
      <button
        style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div style="margin-top: 10px;padding: 10px">
      <div
        style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll;"
        class="scroll"
      >

        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead style="position: sticky; top: 1px">
            <tr style="text-align: center;">
              <th>
                {{ $t('OrderNumber') }}
              </th>
              <th>
                {{ $t('OrderType') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
              <th>
                {{ $t('Price') }}
              </th>
              <th>
                {{ $t('Discount') }}
              </th>
              <th>
                {{ $t('PriceWithDiscount') }}
              </th>
              <th>
                {{ $t('CustomerName') }}
              </th>
              <th>
                {{ $t('paidAmount') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(items, index) in getStoreSoldTracking"
              :key="index"
              style="text-align: center;"
            >
              <td>
                {{ items.orderNumber }}
              </td>
              <td>
                {{ items.orderType }}
              </td>
              <td>
                {{ formatDate(items.created) }}
              </td>
              <td>
                {{ items.price }}
              </td>
              <td>
                {{ items.discount }}
              </td>
              <td>
                {{ items.price - items.discount }}
              </td>
              <td>
                {{ items.clientFullName }}
              </td>
              <td>
                {{ items.paidAmount }}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <b-pagination
            v-if="getTotalItemsForOrders > 15"
            v-model="pageNumber"
            :total-rows="getTotalItemsForOrders"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            prev-class="prev-itemi"
            next-class="next-itemi"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-sidebar>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'
import 'vue2-datepicker/index.css';
import 'jspdf-autotable';

export default {
  components: {
  },
  props: ['data'],
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
    }
  },
  computed: {
    ...mapGetters(['getStoreSoldTracking']),
  },
  watch: {
    pageNumber(value) {
      if (value) {
        this.loadStoresSoldTracking({
          startDate: this.data.startDate,
          endDate: this.data.endDate,
          storeId: this.data.filiale.storeId,
          pageNumber: value,
          pageSize: this.pageSize,
        })
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['loadStoresSoldTracking']),
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>

    <style scoped>
  .team_table td {
    padding: 16px 9px !important;
  }

  .sales {
    width: 12%;
  }
  @media screen and (max-width: 1200px) {
    .scroll {
      overflow-x: scroll;
    }
    .sales {
      width: 21%;
    }
  }
    </style>
